<template>
  <div id="Menu" class="menu">
    <img id="logo" class="logo" v-bind:src="require(`@/assets/images/logo${darkmode}.svg`)" alt="Boldtic" />
    <div id="bars" class="bars">
      <a v-on:click="showBars">
        <span>
          <i class="far fas fa-bars"></i>
        </span>
      </a>
      <div id="barsOptions" class="barsOptions">
        <ul id="barsOption" class="barsOption">
          <li>
            <a href="#cover" v-on:click="showBars">Inicio</a>
          </li>
          <li>
            <a href="#services" v-on:click="showBars">Servicios</a>
          </li>
          <li>
            <a href="#tech" v-on:click="showBars">Tecnologías</a>
          </li>
          <!-- <li>
            <a href="#team" v-on:click="showBars">Equipo</a>
          </li> -->
          <li>
            <a href="#team" v-on:click="showBars">Contacto</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="openMenu">
      <a href="#cover">Inicio</a>
      <a href="#services">Servicios</a>      
      <a href="#tech">Tecnologías</a>   
      <!-- <a href="#team">Equipo</a> -->
      <a href="#team">Contacto</a>
    </div>
    <div>
        <!-- <iframe id="copilot" src="https://copilotstudio.microsoft.com/environments/Default-3c25f937-1651-4afa-a2d4-39a08b6f576e/bots/crcef_boldtic/webchat?__version__=2" frameborder="0"></iframe> -->  
      <div id="copilot">
        <div id="banner">
          <h1>Bold-IA</h1>
        </div>
        <div id="webchat" role="main"></div>
      </div>
    </div>
    <!-- <span>        
        <button class="copilot" v-on:click="showCopilotChat"><img src="../assets/images/boldtic_copilot.svg" alt="boldtic"></button>
    </span> -->
    <span>        
        <button id="mode" class="mode" v-on:click="toggleDarkMode"><span class="fas fa-moon"></span></button>
    </span>
  </div>
</template>
<script>
export default {
  name: "menu-bar",
  data () {
    return {
      darkmode: ''
    }
  },
  methods: {
    iosSafari: function() {
      const userAgent = window.navigator.userAgent
      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        document.getElementById("logo").classList.add('ios');       
        document.getElementById("mode").classList.add('ios'); 
      }
    },
    showCopilotChat: function(){
      if(document.getElementById("copilot").classList.contains("show"))
        document.getElementById("copilot").classList.remove("show");
      else{
        document.getElementById("copilot").classList.add("show");
      }
    },
    toggleDarkMode: function() {
      this.darkmode = this.darkmode === '' ? 'dark' : '';
      document.body.classList.contains("dark")
        ? document.body.classList.remove("dark")
        : document.body.classList.add("dark");
      
      this.darkModeOnByElementById(["cover", "Menu", "mode", "tech-bg", "contactInfo", "barsOption"]);
    },
    darkModeOnByElementById: function(elements) {
      elements.forEach(element => {
        document.getElementById(element).classList.contains("dark")
        ? document.getElementById(element).classList.remove("dark")
        : document.getElementById(element).classList.add("dark");
      });      
    },
    shrinkLogo: function() {
      var logo = document.getElementById("logo")
      var menu = document.getElementById("Menu")
      var bars = document.getElementById("bars")
      var scrollY =
        window.scrollY === undefined
          ? document.documentElement.scrollTop
          : window.scrollY;
      if (20 < scrollY) {
        logo.classList.add("show")
        menu.classList.add("show")
        bars.classList.add("show")
      }
      if (20 > scrollY) {
        logo.classList.remove("show")
        menu.classList.remove("show")
        bars.classList.remove("show")
      }
    },
    showBars: function(){
      document.getElementById('barsOptions').classList.contains('show') ?document.getElementById('barsOptions').classList.remove('show') : document.getElementById('barsOptions').classList.add('show')
    },
    toggleDarkModeAndShowBars: function() {
      this.toggleDarkMode()
      this.showBars()
    },
    initChat: async function () {
      
        const styleOptions = {
          hideUploadButton: true
        };
        const tokenEndpointURL = new URL('https://default3c25f93716514afaa2d439a08b6f57.6e.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crcef_boldtic/directline/token?api-version=2022-03-01-preview');
 
        const locale = 'es-es';

        const apiVersion = tokenEndpointURL.searchParams.get('api-version');
        const directLineURL = await fetch(new URL(`/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`, tokenEndpointURL))
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to retrieve regional channel settings.');
              }

              return response.json();
            })
            .then(({ channelUrlsById: { directline } }) => directline)
        const token = await fetch(tokenEndpointURL)
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to retrieve Direct Line token.');
              }

              return response.json();
            })
            .then(({ token }) => token)
        // eslint-disable-next-line no-unused-vars
        // const [directLineURL, token] = Promise.all([
        //   fetch(new URL(`/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`, tokenEndpointURL))
        //     .then(response => {
        //       if (!response.ok) {
        //         throw new Error('Failed to retrieve regional channel settings.');
        //       }

        //       return response.json();
        //     })
        //     .then(({ channelUrlsById: { directline } }) => directline),
        //   fetch(tokenEndpointURL)
        //     .then(response => {
        //       if (!response.ok) {
        //         throw new Error('Failed to retrieve Direct Line token.');
        //       }

        //       return response.json();
        //     })
        //     .then(({ token }) => token)
        // ]);
        console.log(directLineURL);
      // eslint-disable-next-line no-undef      
        const directLine = WebChat.createDirectLine({ domain: new URL('v3/directline', directLineURL), token });
        const subscription = directLine.connectionStatus$.subscribe({
          next(value) {
            if (value === 2) {
              directLine
                .postActivity({
                  localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  locale,
                  name: 'startConversation',
                  type: 'event'
                })
                .subscribe();
              subscription.unsubscribe();
            }
          }
        });
        // eslint-disable-next-line
        WebChat.renderWebChat({ directLine, locale, styleOptions }, document.getElementById('webchat'));
      }
  },
  mounted() {
    if (new Date().getHours() > 19 || new Date().getHours() < 7) {
      this.toggleDarkMode();
    }
    this.iosSafari();    
    // this.initChat();
  },
  created() {
    window.addEventListener("scroll", this.shrinkLogo)
    window.addEventListener("resize", this.iosSafari)     
    
  },
  unmounted() {
    window.removeEventListener("scroll", this.shrinkLogo)
    window.removeEventListener("resize", this.iosSafari)     
  }
};
</script>
<style scoped>
.menu {
  position: fixed;
  width: 102vw;
  text-align: left;
  margin: 0;
  padding: 25px 0;
  background-color: rgba(255, 255, 255, 0);
  height: 25px;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}
.menu .openMenu {
  position: relative;
  left: 200px;
}
.menu a {
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 30px;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  color: #444;
}
.menu span {
  cursor: default;
  text-transform: uppercase;
  padding: 0 20px;
  font-weight: bold;
}
.menu a::after{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 100%;
    display: inline-block;
    height: 8px;
    background-color: black;
    transition: all 0.3s cubic-bezier(1, 0.68, 0.16, 0.9);
}
.menu a:hover::after{
    right: 0;
    height: 4px;
    background: #007ee5;
}
.menu.dark a {
  color: white;
}
.menu a:focus,
.menu a:hover {
  color: #007ee5;
}
.menu.show {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(3px);
  box-shadow: #666 0px 0.1px 8px; 
}
.menu.dark {
  background-color: rgba(0, 0, 0, 0);
}
.menu.dark.show {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
}
.menu .bars {
  display: none;
}
.bars{
  display: none;
}
.barsOptions {
  display: none;
  position: relative;
  text-align: center;
}
.barsOptions.show {
  display: inline;
}
.barsOption {
  display: block;
  padding: 0;
  list-style-type: none;
  background-color: rgba(255, 255, 255, 0.8);
}
.barsOption.dark {
  background-color: rgba(0, 0, 0, 0.8);
}
.barsOptions ul {
  padding: 5px 0;
}
.barsOptions li {
  margin: 20px 0;
}
.logo {
  position: absolute;
  width: 30vw;
  top: 16vh;
  left: 5vw;
  transition: all 0.6s ease-in-out;
}
.logo.show {
  width: 140px;
  top: 18px;
  left: 20px;
}
#copilot{
    z-index: 1000;
    position: fixed;
    top: 15vh;
    left: 76vw;
    filter: drop-shadow( 0px 5px 10px rgba(0, 0, 0, 0.4));
    width: 22%;
    height: 600px;
    display: none;
    border-radius: 18px;
    background-color: white;
}
#copilot.show{
  display: block;
}
.copilot{
  position: absolute;
  top: 3vh;
  left: 88vw;
  border-radius: 18px;
  border: 0px;
  width: 160px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow:rgba(0,0,0,0.4) 0px 10px 20px;
  cursor: pointer;
}
.copilot img{
  width: 124px;
}

#banner {
        align-items: center;
        background-color: #007ee5;
        display: flex;
        height: 50px;
      }
      #banner h1{
        align-items: center;
        font-size: 1em;
        text-align: center;
        color:white;
      }

      #webchat {
        height: calc(100% - 50px);
        overflow: hidden;
        position: fixed;
        top: 50px;
        width: 100%;
      }
.mode {
  position: absolute;
  top: 93vh;
  left: 2vw;
  border-radius: 50%;
  border: 0px;
  width: 40px;
  height: 40px;
  background-color: black;
  color: white;
  box-shadow:rgba(0,0,0,0.4) 0px 10px 20px;
  cursor: pointer;
}
.mode.dark{
  background-color: white;
  color:black;
}
@media only screen and (max-width: 1024px) {
    .menu .openMenu{
        display: none;
    }
    .menu .bars{
        /* display: block; */
        text-align: right;
        width: 100%;
    }
    .bars span{
        padding: 0 10px;       
    }
    .bars a{
        cursor: pointer;
    }
    .bars.show{
      display: block !important;
    }
}
@media only screen and (max-width: 959px) {
    .menu .openMenu{
        display: none;
    }
    .menu .bars{
        /* display: block; */
        text-align: right;
        width: 100%;
    }
    .bars span{
        /* font-size: 1.5em; */
        padding: 0 10px;       
    }
    .bars a{
        cursor: pointer;
    }
    
}

@media only screen and (max-width: 431px) {
    .logo{
        top: -75vh;
        left: 7vw;
        width:45vw;
    }
    .ios{
      top: -60vh
    }
    .logo.show {
      width: 120px;
      top: 25px;
      left: 20px;
    } 
    .menu{
        height: 50px;
        bottom:0px;
        background-color:rgba(255, 255, 255, 0.8);
    }
    /* .menu .bars{
      display: block;
    } */
    .bars span{
        padding: 0;       
    }
    .barsOptions{
        top:-262px;
    }
    .cover{
        background-position: right;
    }
    .cover h1{
        top:25vh;
        left: 15vw;
        font-size: 14vw;
    }
    .mode {
      top: -110vh;
      left: 86vw;
    }
}
</style>