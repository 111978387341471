<template>
  <div>
    <div id="cover" class="cover">
      <NavMenu />
        <h1 class="blue">
            <span class="typed-text"></span>
        </h1>
    </div>
    <div id="whyboldtic" class="content" style="opacity: 1; top: 0;">
        <p><span class="blue">Boldtic</span> nace de la visión de un nuevo tipo de consultoría, donde el trabajo que realizamos sea un beneficio para nuestros clientes y un desafío para nuestros colaboradores.</p>
        <p>Debido a la rápida evolución tecnológica, las empresas se enfrentan a desafíos constantes, necesitando un acompañamiento por asesores especializados, que dispongan del conocimiento y las herramientas necesarias para abordar el cambio.</p>
        <p>Enfocamos la transformación digital en la automatización de procesos, operaciones, y la evolución del puesto de trabajo, combinando el trabajo presencial con el trabajo en remoto.</p>
        <p>Apoyamos a las personas durante todo el proceso, mediante metodologías de adopción.</p>
        <p>Creemos en las nuevas formas de trabajar, ofreciendo flexibilidad horaria y teletrabajo a nuestros colaboradores desde el primer día, facilitando la conciliación personal y familiar.</p>
    </div>
    <section id="services">
        <div style="display: table">
            <div style="display:table-row">
                <div id="workplace" class="content-2C" style="text-align: right;">
                    <span class="title blue">Workplace</span>
                    <p>Ayudamos a la gestión los dispositivos de trabajo (sobremesa, portátiles, tablets y móviles) mediante soluciones en la nube, locales e hibridas, adaptándolas a los diferentes escenarios de cada cliente.</p>
                    <p>Somos especialistas en la implantación de la solución integrada de Microsoft Endpoint Manager, que engloba Intune y System Center Configuration Manager, utilizada para administrar los diferentes dispositivos.</p>
                </div>
                <div id="cloud" class="content-2C top">
                    <span class="title grey">Cloud</span>
                    <p>Apostamos por los servicios cloud de Microsoft 365 y Azure para modernizar los procesos de gestión, seguridad, backup y análisis, permitiendo reducir los costes de mantenimiento e infraestructura, y asegurando una disponibilidad del servicio muy superior a la de un entorno local.</p>
                </div>
            </div>
            <div style="display:table-row">
            <div id="dev" class="content-2C" style="text-align: right;">
                <span class="title blue">Desarrollo</span>
                <p>Desarrollamos soluciones para completar y mejorar procesos de negocio.</p> 
                <p>Aplicaciones de escritorio, web y móviles, con base en servicios en la nube, siguiendo los nuevos patrones de desarrollo y diseño.</p>
                <p>Integramos servicios de Microsoft 365 con los procesos empresariales, con el objetivo de sacar el mayor provecho de las herramientas de productividad.</p>
            </div>
            <div id="advisor" class="content-2C top">
                <span class="title grey">Consultoría</span>
                <p>Asesoramos y orientamos a las empresas, analizando las áreas con opción de mejora o transformación, y proponemos soluciones de mercado que se adapten a las necesidades del cliente.</p>
                <p>Diseñamos conjuntamente un plan de acción a medida, para que sea implementado por el cliente o por nuestro equipo de colaboradores.</p>
            </div>
            </div>
            <div id="support" class="content-2C" style="text-align: right;">
                <span class="title blue">Servicios Gestionados</span>
                <p>Ofrecemos servicios especializados, que permiten complementar y ampliar el conocimiento del cliente, sobre las plataformas o tecnologías que requieren de una mayor especialización.</p>
                <p>Establecemos sistemas de monitorización activos, que permiten adaptarnos de forma rápida a la plataforma, y detectar los puntos más críticos ante cualquier situación.</p>
            </div>
        </div>
        </section>
        <section id="tech-bg">
        <div id="tech" class="content-tech">
            <div class="tech-group">
                <div class="maintech">
                    <img src="./assets/images/MEM.svg" alt="Microsoft Endpoint Manager">
                </div>
                <div class="tech col2">
                    <span alt="Windows">
                        <img src="./assets/images/windows.svg" alt="Windows">                
                    </span>
                    <span alt="Intune">
                        <img src="./assets/images/intune.svg" alt="Intune">
                    </span>
                    <span alt="System Center">
                        <img src="./assets/images/systemcenter.svg" alt="System Center">
                    </span>
                    <span alt="Windows 365">
                        <img src="./assets/images/w365.svg" alt="Windows 365">
                    </span>
                </div>
            </div>                
            <div class="tech-group">
                <div class="maintech">
                    <img src="./assets/images/powerplatform.svg" alt="Power Platform">
                </div>
                <div class="tech col2">
                    <span alt="PowerApps">                
                        <img src="./assets/images/powerapps.svg" alt="PowerApps">
                    </span>
                    <span alt="Power Automate">
                        <img src="./assets/images/powerautomate.svg" alt="Power Automate">
                    </span>
                    <span alt="Power BI">
                        <img src="./assets/images/powerbi.svg" alt="Power BI">
                    </span>
                    <span alt="Power Virtual Agents">
                        <img src="./assets/images/powervirtualagents.svg" alt="Power Virtual Agents">
                    </span>
                </div>
            </div>
            <div class="tech-group">
                <div class="maintech">
                    <img src="./assets/images/M365.svg" alt="Microsoft 365">
                </div>
                <div class="tech col2">    
                    <span alt="Microsoft Teams">                
                        <img src="./assets/images/teams.svg" alt="Microsoft Teams">
                    </span>
                    <span alt="SharePoint Online">
                        <img src="./assets/images/sharepoint.svg" alt="SharePoint Online">
                    </span>
                    <span alt="Microsoft Defender">
                        <img src="./assets/images/atp.svg" alt="Microsoft Defender">
                    </span>
                    <span alt="Exchange">
                        <img src="./assets/images/exchange.svg" alt="Exchange">
                    </span>
                </div>
            </div>
            <div class="tech-group">
                <div class="maintech">
                    <img src="./assets/images/azure.svg" alt="Azure">
                </div>
                <div class="tech col2">  
                    <span alt="Azure Virtual Desktop">              
                        <img src="./assets/images/WVD.svg" alt="Azure Virtual Desktop">
                    </span>
                    <span alt="Microsoft Entra">
                        <img src="./assets/images/entra.svg" alt="Microsoft Entra">
                    </span>
                    <span alt="Azure IA">
                        <img src="./assets/images/cognitive-services.svg" alt="Azure IA">
                    </span>
                    <span alt="Conditional Access">
                        <img src="./assets/images/ca.svg" alt="Conditional Access">
                    </span>
                </div>
            </div>
        </div>
    </section>
    <div id="team" class="content">
        <div class="innerContent flex">
            <div>
                <p>Nuestro equipo cuenta con una amplia experiencia en transformación digital ¿En qué podemos ayudarte?</p>
            </div>
            <img class="partner" src="./assets/images/microsoftpartner.png" alt="Microsoft Partner">
        </div>
        <div class="team3layout innerContent flex">
            <div class="team-card">
                <img src="./assets/images/team/miguel.png" alt="Miguel Galindo">
                <div class="flex">
                    <div>
                        <span>
                            <a href="https://www.linkedin.com/in/miguel-galindo-gascon" target="_blank">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </span>
                    </div>
                    <div>
                        <h3>Miguel Galindo</h3>
                        <h4>Solutions Director</h4>
                        <h5>Head of Services</h5>
                    </div>
                </div>
            </div>
            <div class="team-card">
                <img src="./assets/images/team/daniel.png" alt="Daniel Preguezuelos">
                <div class="flex">
                    <div>
                        <span>
                            <a href="https://www.linkedin.com/in/dpreguezuelos" target="_blank">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </span>
                    </div>
                    <div>
                        <h3>Daniel Preguezuelos</h3>
                        <h4>General Manager</h4>
                        <h5>Head of Development</h5>
                    </div>
                </div>
            </div>
            <div class="team-card">
                <img src="./assets/images/team/pablo.png" alt="Pablo Enrique Isla">
                <div class="flex">
                    <div>
                        <span>
                            <a href="https://www.linkedin.com/in/pabloeisla" target="_blank">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </span>
                    </div>
                    <div>
                        <h3>Pablo Enrique Isla</h3>
                        <h4>Innovation Director</h4>
                        <h5>Head of Infraestructure</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div id="contact" class="rowContent" style="max-height: 480px">
        <LocationMap />
        <div id="contactInfo" class="contact">
            <div class="flex">
                <span>
                    <i class="fas fa-map-marker-alt"></i>
                </span>
                 <h4>Avenida de la Via Augusta 15-25, 08174 <br> Sant Cugat del Vallès <br>Barcelona</h4>
            </div>
            <div class="flex">
                <span>
                    <i class="fas fa-phone"></i>
                </span>
                <h4>+34 936 064 063</h4>
            </div>
            <div class="flex">
                <span>
                    <i class="fas fa-envelope"></i>
                </span>
                <h4>info@boldtic.com</h4>
            </div>
        </div>
    </div>
    <div class="rowContent right">
        <div>
            <h3 style="padding:10px 20px">© 2024 Boldtic</h3>
        </div>
    </div>
</template>

<script>
import NavMenu from './components/NavMenu.vue'
import LocationMap from './components/LocationMap.vue'

export default {
  name: 'app',
  components: {
    NavMenu,
    LocationMap
  },
  methods:{
    showToggle: function(elements) {
        elements.forEach(element => {          
            var object = document.getElementById(element.id);
            var offsetTop = object.offsetTop === undefined ? object.clientTop : object.offsetTop;
            var scrollY = window.scrollY === undefined ? document.documentElement.scrollTop : window.scrollY;

            if (offsetTop < scrollY + window.innerHeight - element.position) {
                object.classList.add('show');
            }
            if (offsetTop > scrollY + window.innerHeight) {
                object.classList.remove('show');
            }
        });
    },
    callInOut: function(){
        const elements = [
                            {
                                id: 'whyboldtic',
                                position: 300
                            },
                            {
                                id: 'workplace',
                                position: 300
                            },
                            {
                                id: 'cloud',
                                position: 300
                            },
                            {
                                id: 'dev',
                                position: 300
                            },
                            {
                                id: 'advisor',
                                position: 300
                            },
                            {
                                id: 'support',
                                position: 300
                            },
                            {
                                id: 'tech',
                                position: 200
                            },
                            {
                                id: 'team',
                                position: 300
                            }
                        ]
        this.showToggle(elements)
    }
  },
  created() {
    window.addEventListener("scroll", this.callInOut);       
  },
  unmounted() {
    window.removeEventListener("scroll", this.callInOut);
  }
}
</script>