<template>
    <div id="map" ref="map" style="height:480px;"></div>
</template>
<script>
import pushpinIcon from "../assets/images/pushpin.svg"
var map;
export default {
    name: "map-control",
    methods: {
        InitMap: function(){
            let mapElement = this.$refs.map;
            // eslint-disable-next-line no-undef
            map = new Microsoft.Maps.Map(mapElement, {
                credentials: "AljGUCuPV_Yybz8o09_E43Nt3noMLWVptlOMPDIUZv7PCqEAa_lPjIOMCXQD9Jd_",
                // eslint-disable-next-line no-undef
                navigationBarMode: Microsoft.Maps.NavigationBarMode.minified,
                // eslint-disable-next-line no-undef
                mapTypeId: document.body.classList.contains('dark') ? Microsoft.Maps.MapTypeId.canvasDark : Microsoft.Maps.MapTypeId.canvasLight
            });
            map.setView({
                // eslint-disable-next-line no-undef
                center: new Microsoft.Maps.Location(41.483998, 2.051456),
                zoom: 13
            });
            map.setOptions({
                disableScrollWheelZoom: true
            });
            // eslint-disable-next-line no-undef
            var pushpin = new Microsoft.Maps.Pushpin(map.getCenter(), {
                icon: pushpinIcon
            });

            map.entities.push(pushpin);    
        },
        ToggleModeMap: function () {
            map.setView({
                // eslint-disable-next-line no-undef
                mapTypeId: document.body.classList.contains('dark') ? Microsoft.Maps.MapTypeId.canvasDark : Microsoft.Maps.MapTypeId.canvasLight
            })
        }
    },
    mounted() {
          document.getElementById("mode").addEventListener("click", this.ToggleModeMap);
        // if (document.getElementById("scriptBingMaps")) {
        // return; 
        // }
        (window).OnLoadBingMapsApi = () => this.InitMap();        
        var scriptTag = document.createElement("script");
        scriptTag.src = "https://www.bing.com/api/maps/mapcontrol?callback=OnLoadBingMapsApi";
        scriptTag.id = "scriptBingMaps";
        document.head.appendChild(scriptTag);       
    },
    unmounted() {
         document.getElementById("mode").removeEventListener("click", this.ToggleModeMap);
    }
};
</script>
<style scoped>
@media only screen and (max-width: 424px){
    #map{
        opacity: 0;
    }
}
</style>